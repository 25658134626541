// echarts 自适应父盒子变化大小
import { followAuthor, likeInformation } from "@/api/user";
import { getToken } from "@/utils/auth";
const myMixin = {
	data() {
		return {
			islike: false,
		};
	},
	methods: {
		resize() {
			// 当宽高变化时就会执行
			//执行某些操作 重新改变图表， 同时传参，设置动画效果
			this.myChart.resize({ animation: { duration: 1000 } });
		},
		ifUrl(sUrl) {
			var bIsUrl = false;
			var rUrl = new RegExp(
				"(http://|https://)?((?:[A-Za-z0-9]+-[A-Za-z0-9]+|[A-Za-z0-9]+).)+([A-Za-z]+)[/?:]?."
			);
			if (sUrl != "" && sUrl != undefined && sUrl.match(rUrl) != null) {
				bIsUrl = true;
			}
			return bIsUrl;
		},
		toTree(data) {
			let treeData = [];
			if (!Array.isArray(data)) return treeData;

			data.forEach((item) => {
				delete item.children; //删除item下的children，以防多次调用
			});

			let map = {};
			data.forEach((item) => {
				map[item.id] = item;
			});

			data.forEach((item) => {
				item["value"] = item.id;
				item["label"] = item.name;
				let parent = map[item.pid]; //判断item的pid是否是否存在map中
				if (parent) {
					//如果存在则表示item不是最顶层的数据
					(parent.children || (parent.children = [])).push(item);
				} else {
					treeData.push(item); // 如果不存在 则是顶层数据
				}
			});
			return treeData;
		},
		// 关注
		followAuthorFun(id, is_status, detailId) {
			const hasToken = getToken();
			// console.log(hasToken);
			if (window.screen.width < 800) {
				this.$router.push({
					path: "/download",
				});
				return;
			}
			if (hasToken) {
				followAuthor({ author_id: id, is_status: is_status })
					.then((res) => {
						if (res.code == 200) {
							this.$message.success("操作成功！");
							if (detailId) {
								this.gettoUserInfo(detailId);
							} else {
								this.gettoUserInfo(this.$route.query.id);
							}
						}
					})
					.catch((error) => {
						// this.$message.error(error.message);
					});
			} else {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			}
		},
		// 利好利空
		likeInformation(id, type, is_like, page) {
			const hasToken = getToken();
			// console.log(hasToken);
			if (window.screen.width < 800) {
				this.$router.push({
					path: "/download",
				});
				return;
			}
			if (hasToken) {
				let is_status = null;
				is_like == 0 ? (is_status = 1) : (is_status = 0);
				likeInformation({ id: id, type: type, is_status: is_status })
					.then((res) => {
						if (res.code == 200) {
							if (page == "detail") {
								this.getInformationDetails(this.$route.query.id);
							} else {
								this.getArticleList(
									{
										category_id: this.categoryLists[this.active].id,
										type: 2,
										page: this.page,
										size: this.size,
									},
									0
								);
							}
						}
					})
					.catch((error) => {
						// this.$message.error(error.message);
					});
			} else {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			}
		},
		getNewData(data, newData) {
			data.forEach((item, i) => {
				let index = -1;
				let isExists = newData.some((newItem, j) => {
					if (
						this.getYearMonth(item.release_time) ==
						this.getYearMonth(newItem.release_time2)
					) {
						index = j;
						return true;
					}
				});
				// console.log(isExists);
				let new_create_date =
					this.getYearMonth(item.release_time).split("-")[1] +
					"/" +
					this.getYearMonth(item.release_time).split("-")[2];
				let days = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"];
				var today = new Date(Date.parse(item.release_time.replace(/-/g, "/")));
				var weekday = today.getDay();
				var weekDay = days[weekday];

				let diffDays = ["今天", "昨天", "前天"];
				var diffday = this.getTotalDate(item.release_time);
				var diffDay = null;

				if (diffday < 3) {
					diffDay = diffDays[diffday];
				}
				item.isRecommend = false;
				if (item.label_info.length > 0) {
					item.label_info.forEach((label) => {
						if (label.id == 29) {
							item.isRecommend = true;
						} else {
						}
					});
				}

				if (!isExists) {
					newData.push({
						release_time: new_create_date,
						week_time: weekDay,
						diff_time: diffDay,
						release_time2: item.release_time,
						subList: [item],
					});
				} else {
					newData[index].subList.push(item);
				}
			});
			return newData;
		},

		dataResort(arr, newData) {
			//console.log(this.dataAll)
			var dataAll = arr;
			const that = this;
			dataAll.forEach((item, index) => {
				item.release_time = that.getYearMonth(item.release_time);
			});
			console.log(dataAll);
			//return false;
			//定义一个空数组
			let newArr = [];
			//通过forEach循环数组
			arr.forEach((item, i) => {
				let index = -1;
				//然后在跑到这里筛选 根据不同的时间放置不同的数组    some（）用来查找数组中是否存在某个值  如果存在 就return true
				let isExists = newArr.some((newItem, j) => {
					if (
						that.getYearMonth(item.release_time) ==
						that.getYearMonth(newItem.release_time)
					) {
						index = j;
						return true;
					}
				});
				//代码是先跑这里的if条件判读
				if (!isExists) {
					newArr.push({
						time: item.release_time,
						subList: [item],
					});
				} else {
					newArr[index].subList.push(item);
				}
				newData = newArr;
			});
			return newData;
		},
		getYearMonth(date) {
			// 将日期以空格隔开，即['2020-06-13', '17:10:09']
			date = (date + "").split(/[ ]+/);
			let result = "";
			let reg = /^(\d{4})-(\d{1,2})-(\d{1,2})$/;
			// 用截取出来的年月日进行正则表达式匹配
			reg.exec(date[0]);
			result = RegExp.$1 + "-" + RegExp.$2 + "-" + RegExp.$3;

			return result;
		},
		getTotalDate(sDate) {
			// var date2 = new Date();
			var date1 = new Date(Date.parse(sDate.replace(/-/g, "/")));
			// var totalDate = parseInt(
			// 	Math.abs(date2.getTime() - date1.getTime()) / 1000 / 60 / 60 / 24
			// );
			const today = new Date();
			const yesterday = new Date(today);
			yesterday.setDate(yesterday.getDate() - 1);
			const beforeYesterday = new Date(today);
			beforeYesterday.setDate(beforeYesterday.getDate() - 2);

			if (
				date1.getDate() === today.getDate() &&
				date1.getMonth() === today.getMonth() &&
				date1.getFullYear() === today.getFullYear()
			) {
				return 0;
			} else if (
				date1.getDate() === yesterday.getDate() &&
				date1.getMonth() === yesterday.getMonth() &&
				date1.getFullYear() === yesterday.getFullYear()
			) {
				return 1;
			} else if (
				date1.getDate() === beforeYesterday.getDate() &&
				date1.getMonth() === beforeYesterday.getMonth() &&
				date1.getFullYear() === beforeYesterday.getFullYear()
			) {
				return 2;
			}
			return 3;
		},
	},

	//自定义指令，图表的宽度采用百分比，父盒子宽度变化，那图表盒子大小也变化了，但是图表不会重新绘制
	//原理：判断盒子本身宽度改变了，再调用echarts的resize方法重新绘制
	directives: {
		// 使用局部注册指令的方式
		resize: {
			// 指令的名称
			bind(el, binding) {
				// el为绑定的元素，binding为绑定给指令的对象
				let width = "",
					height = "";
				function isReize() {
					//这个方法可以获取元素的css样式对象
					const style = document.defaultView.getComputedStyle(el);
					//对比跟上次宽度是否改变，如果改变了
					if (width !== style.width || height !== style.height) {
						//调用resize方法
						binding.value(); // 关键
					}
					//记录当前宽高
					width = style.width;
					height = style.height;
				}
				//设置监听器，每隔一段时间对比看看
				el.__vueSetInterval__ = setInterval(isReize, 300);
			},
			//只调用一次，指令与元素解绑时调用
			unbind(el) {
				//清除定时器
				clearInterval(el.__vueSetInterval__);
			},
		},
	},
};

export { myMixin };
